import React, { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';

import RouteGuard from './RouteGuard';
import SuspenseLoader from './components/SuspenseLoader';
import BaseLayout from './layouts/BaseLayout';
import SidebarLayout from './layouts/SidebarLayout';

// eslint-disable-next-line react/display-name, no-undef
const Loader = (Component: any) => (props: JSX.IntrinsicAttributes) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const StripeRedirect = Loader(lazy(() => import('./pages/StripeRedirect/StripeRedirect')));
const SignUpPage = Loader(lazy(() => import('./pages/SignUp/signUp')));
const ManagedUnmanaged = Loader(
  lazy(() => import('./pages/SelectManagedUnmanaged/SelectManagedUnmanaged')),
);
const CreditScoreInfo = Loader(lazy(() => import('./pages/CreditScoreInfo')));
const Settings = Loader(lazy(() => import('./pages/Settings')));
const AprilTaxPage = Loader(lazy(() => import('./pages/Tax')));
const TestPage = Loader(lazy(() => import('./pages/TestPage')));
const RentersToolkit = Loader(lazy(() => import('./pages/RentersToolkit')));
const SampleConsumerPage = Loader(
  lazy(() => import('./pages/SampleSignUpConsumer/SampleSignUpConsumer')),
);
const SignUpOtpSelection = Loader(
  lazy(() => import('./pages/SignUpOtpSelection/SignUpOtpSelection')),
);
const OtpVerification = Loader(lazy(() => import('./pages/OtpVerification/OtpVerification')));
const OtpVerificationCode = Loader(
  lazy(() => import('./pages/OtpVerificationCode/OtpVerificationCode')),
);
const SSNVerification = Loader(lazy(() => import('./pages/SSNNineDigits/SSNNineDigits')));
const Address = Loader(lazy(() => import('./pages/AddressPage/AddressPage')));
const ManagedResidentConfirmation = Loader(
  lazy(() => import('./pages/ManagedResidentConfirmation/ManagedResidentConfirmation')),
);

const SSNPage = Loader(lazy(() => import('./pages/SSNPage/SSNPage')));
const VerificationPage = Loader(lazy(() => import('./pages/VerificationPage/VerificationPage')));
const EnrolledPage = Loader(lazy(() => import('./pages/Enrollment/Enrolled')));
const NotEnrolledPage = Loader(lazy(() => import('./pages/Enrollment/NotEnrolled')));
const EnrollmentSuccededPage = Loader(lazy(() => import('./pages/Enrollment/EnrollmentSucceded')));
const OptOutPage = Loader(lazy(() => import('./pages/OptOutPage')));
const PhoneVerificationPage = Loader(lazy(() => import('./pages/PhoneVerification')));
const VerificationFailedPage = Loader(
  lazy(() => import('./pages/VerificationFailed/verification-failed')),
);
const SecureMFAOptionsPage = Loader(
  lazy(() => import('./pages/SecureMFAOptions/SecureMFAOptions')),
);
const SMFAWaitingPage = Loader(lazy(() => import('./pages/SMFAWaitingPage/index')));
const Offers = Loader(lazy(() => import('./pages/Offers/index')));
const RentReporting = Loader(lazy(() => import('./pages/RentReporting/RentReporting')));
const RentersPassport = Loader(lazy(() => import('./pages/RentersPassport/RentersPassport')));
const UnmanagedPhonePage = Loader(
  lazy(
    () => import('./pages/UnmanagedResidentPhoneVerification/UnmanagedResidentPhoneVerification'),
  ),
);
const AllSetPage = Loader(
  lazy(() => import('./pages/IndependentResidentAllSet/IndependentResidentAllSet')),
);
const NotVerifiedPage = Loader(lazy(() => import('./pages/NotVerified/NotVerified')));

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/stripe-redirect',
        element: <StripeRedirect />,
      },
      {
        path: '/sign-up',
        element: <SignUpPage />,
      },
      {
        path: '/select-status',
        element: <ManagedUnmanaged />,
      },
      {
        path: '/',
        element: <Navigate to="/sign-up" replace />,
      },
      //deprecated component
      {
        path: '/test',
        element: <SampleConsumerPage />,
      },
      {
        path: '/otp-selection',
        element: <SecureMFAOptionsPage />,
      },
      //deprecated component
      {
        path: '/otp',
        element: <SignUpOtpSelection />,
      },
      //deprecated component
      {
        path: '/otp-verification',
        element: <OtpVerification />,
      },
      {
        path: '/ssn-page',
        element: <SSNPage />,
      },
      //deprecated component
      {
        path: '/code',
        element: <OtpVerificationCode />,
      },
      {
        path: '/ssn-verification',
        element: <SSNVerification />,
      },
      {
        path: '/not-verified',
        element: <NotVerifiedPage />,
      },
      {
        path: '/unmanaged-phone-verification',
        element: <UnmanagedPhonePage />,
      },
      {
        path: '/all-set',
        element: <AllSetPage />,
      },
      {
        path: '/address',
        element: <Address />,
      },
      //deprecated component
      {
        path: '/verify-account',
        element: <ManagedResidentConfirmation />,
      },
      {
        path: '/otp-waiting',
        element: <SMFAWaitingPage />,
      },
      {
        path: '/verify',
        element: <VerificationPage />,
      },
      {
        path: '/phone-verification',
        element: <PhoneVerificationPage />,
      },
      {
        path: '/verification-failed',
        element: <VerificationFailedPage />,
      },
      {
        path: '/enrolled',
        element: <EnrolledPage />,
      },
      //deprecated component
      {
        path: '/not-enrolled',
        element: <NotEnrolledPage />,
      },
      //deprecated component
      {
        path: '/enrollment-successful',
        element: <EnrollmentSuccededPage />,
      },
      //deprecated component
      {
        path: '/opt-out',
        element: <OptOutPage />,
      },
      {
        path: '*',
        element: <SignUpPage />,
      },
    ],
  },
  {
    path: 'dashboard',
    element: <SidebarLayout />,
    children: [
      {
        path: '/dashboard',
        element: <Navigate to="credit-score-info" replace />,
      },
      {
        path: '/dashboard/credit-score-info',
        element: (
          <RouteGuard redirectTo="/dashboard/offers" protectedRouteType="accountInfo">
            <CreditScoreInfo />
          </RouteGuard>
        ),
      },
      {
        path: '/dashboard/rent-reporting',
        element: (
          <RouteGuard redirectTo="/dashboard/offers" protectedRouteType="accountInfo">
            <RentReporting />
          </RouteGuard>
        ),
      },
      {
        path: '/dashboard/renters-passport',
        element: (
          <RouteGuard redirectTo="/dashboard/offers" protectedRouteType="rentersPassport">
            <RentersPassport />
          </RouteGuard>
        ),
      },
      {
        path: '/dashboard/renters-toolkit',
        element: <RentersToolkit />,
      },
      {
        path: '/dashboard/offers',
        element: <Offers />,
      },
      {
        path: '/dashboard/settings',
        element: <Settings />,
      },
      {
        path: '/dashboard/test-page',
        element: (
          <RouteGuard redirectTo="/dashboard/offers" protectedRouteType="testPage">
            <TestPage />
          </RouteGuard>
        ),
      },
      {
        path: '/dashboard/tax',
        element: (
          <RouteGuard redirectTo="/dashboard/offers" protectedRouteType="accountInfo">
            <AprilTaxPage />
          </RouteGuard>
        ),
      },
      {
        path: '*',
        element: <Navigate to="credit-score-info" replace />,
      },
    ],
  },
];

export default routes;
