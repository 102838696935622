import React, { useContext } from 'react';

import {
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import { SidebarContext } from '../../../../context/SidebarContext';

import { deepGreen } from '@esusu2017/component-library';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BugReportIcon from '@mui/icons-material/BugReport';
import ConstructionIcon from '@mui/icons-material/Construction';
import HomeIcon from '@mui/icons-material/Home';
import PaymentIcon from '@mui/icons-material/Payment';
import SettingsIcon from '@mui/icons-material/Settings';
import SpeedOutlined from '@mui/icons-material/SpeedOutlined';
import Divider from '@mui/material/Divider';
import Cookies from 'js-cookie';
import RouteGuard, { ProtectedRoutes } from '../../../../RouteGuard';
import { buildSxProps } from '../../../../helpers/buildSxProps';
import useFeatureFlag from '../../../../hooks/useFeatureFlag';
import API from '../../../../services/Api';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(0.4)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${alpha(theme.palette.common.white, 0.5)};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.1;
    }
`,
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.palette.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${alpha(theme.palette.common.white, 0.7)};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${alpha(theme.palette.common.white, 0.3)};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${alpha(theme.palette.common.white, 0.5)};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.palette.common.white, 0.06)};
            color: ${theme.palette.common.white};

          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.palette.common.white};
                opacity: 0;
                transition: ${theme.transitions.create(['transform', 'opacity'])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`,
);

const sxProps = {
  menuItemLabel: buildSxProps({
    color: deepGreen[100],
  }),
  divider: buildSxProps({
    borderColor: deepGreen[700],
    mx: 3,
  }),
} as const;

interface SideMenuItemProps {
  protectedRouteType?: keyof typeof ProtectedRoutes;
  badgeId?: string;
  icon: React.ReactNode;
  to?: string;
  label: string;
  onClick?: VoidFunction;
}

const SideMenuItem = ({
  protectedRouteType,
  badgeId,
  icon,
  to,
  label,
  onClick,
}: SideMenuItemProps) => {
  const { closeSidebar } = useContext(SidebarContext);
  const hasBadge = !!badgeId;
  const badgeClicked = badgeId ? Cookies.get(badgeId) : false;
  const markBadgeClicked = () => badgeId && Cookies.set(badgeId, 'true');
  const MainContent = (
    <ListItem>
      <Button
        disableRipple
        component={to ? RouterLink : 'button'}
        onClick={() => {
          if (!badgeClicked) {
            markBadgeClicked();
          }
          onClick?.();
          closeSidebar();
        }}
        to={to ?? undefined}
        startIcon={icon}
      >
        <Typography variant="h3" sx={sxProps.menuItemLabel}>{`${label} `}</Typography>
        {hasBadge && !badgeClicked && (
          <IconButton aria-label="New" sx={{ right: '-40px' }}>
            <Badge badgeContent="New" color="secondary" />
          </IconButton>
        )}
      </Button>
    </ListItem>
  );

  if (protectedRouteType) {
    return <RouteGuard protectedRouteType={protectedRouteType}>{MainContent}</RouteGuard>;
  }

  return MainContent;
};

function SidebarMenu() {
  const { closeSidebar } = useContext(SidebarContext);
  const navigate = useNavigate();
  const canSeeTestPage = useFeatureFlag('testPage');

  const handleLogOut = () => {
    //identical codes in .then and .catch blocks shows that if error is encountered on logout, logout will still be successful
    API.Logout()
      .then(() => {
        closeSidebar();
        navigate('/verify?logoutCurrentUser=true');
      })
      .catch(() => {
        closeSidebar();
        navigate('/verify?logoutCurrentUser=true');
      });
  };

  return (
    <MenuWrapper>
      <List>
        <SubMenuWrapper>
          <List>
            <SideMenuItem
              to="/dashboard/credit-score-info"
              icon={<SpeedOutlined />}
              label="Credit Score"
              protectedRouteType="accountInfo"
            />
            <SideMenuItem
              to="/dashboard/rent-reporting"
              icon={<HomeIcon />}
              label="Rent Reporting"
              protectedRouteType="accountInfo"
              badgeId="hasSeenRentReportingPage"
            />
            <SideMenuItem
              to="/dashboard/renters-passport"
              icon={<AccountBalanceWalletOutlinedIcon />}
              label="Esusu Passport"
              protectedRouteType="rentersPassport"
              badgeId="hasSeenRentersPassport"
            />
            <SideMenuItem
              to="/dashboard/offers"
              icon={<PaymentIcon />}
              label="Marketplace Offers"
              badgeId="hasSeenOffers"
            />
            <SideMenuItem
              to="/dashboard/renters-toolkit"
              icon={<ConstructionIcon />}
              label="Renters Toolkit"
              badgeId="hasSeenRentalToolKitPage"
            />
            <SideMenuItem
              protectedRouteType="accountInfo"
              to="/dashboard/tax"
              icon={<AttachMoneyIcon />}
              label="Tax"
              badgeId="hasSeenTax"
            />
            <SideMenuItem to="/dashboard/settings" icon={<SettingsIcon />} label="Settings" />
            {canSeeTestPage && (
              <SideMenuItem to="/dashboard/test-page" icon={<BugReportIcon />} label="Test Page" />
            )}
          </List>
        </SubMenuWrapper>
      </List>
      <Divider sx={sxProps.divider} />
      <List>
        <SubMenuWrapper>
          <List>
            <SideMenuItem icon={<AccountCircleOutlined />} label="Log Out" onClick={handleLogOut} />
          </List>
        </SubMenuWrapper>
      </List>
    </MenuWrapper>
  );
}

export default SidebarMenu;
