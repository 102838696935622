import Backdrop from '@mui/material/Backdrop';
import type { LottieRefCurrentProps } from 'lottie-react';
import Lottie from 'lottie-react';
import type { FC } from 'react';
import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import confetti from '../animations/confetti.json'; // the lottie animation json data
import { buildSxProps } from '../helpers/buildSxProps';

const DEFAULT_ANIMATION_TIME_IN_MS = 2500;
const DEFAULT_ANIMATION_SPEED = 3;

const sxProps = {
  backdrop: buildSxProps({
    zIndex: (theme) => theme.zIndex.drawer + 1,
    pointerEvents: 'none', // allow clicking behind the backdrop
  }),
};

let animationTimout: NodeJS.Timeout | null = null;

interface AnimationContextState {
  /**
   * Used to know when the animation is currently visible
   */
  animationVisible: boolean;
  /**
   * Function to start the full screen animation.
   * - these are used to celebrate when the user does or completes something
   * @param animationMsTime how long until the animation will be un-rendered automatically
   */
  startAnimation: (imaganimationMsTime?: number) => void;
  /**
   * Normally used internally. Animations should stop on their own.
   * For stopping the animation early for some reason.
   */
  stopAnimation: VoidFunction;
}

export const AnimationContext = createContext<AnimationContextState>({
  animationVisible: false,
  startAnimation: () => {},
  stopAnimation: () => {},
});

/**
 * Used to animate fullscreen `Lottie` animations
 */
export const AnimationProvider: FC = ({ children }) => {
  const [animationVisible, setAnimationVisible] = useState(false);
  const lottieRef: React.MutableRefObject<LottieRefCurrentProps | null> = useRef(null);

  // clear any pending timeouts if this component were to be unmounted early
  useEffect(() => {
    return () => {
      if (animationTimout) {
        clearTimeout(animationTimout);
        animationTimout = null;
      }
    };
  }, []);

  const startAnimation = useCallback(
    (animationTimeMs = DEFAULT_ANIMATION_TIME_IN_MS) => {
      setAnimationVisible(true);

      // only display for as long as the animation is playing
      animationTimout = setTimeout(() => {
        setAnimationVisible(false);
        lottieRef.current = null;
        animationTimout = null;
      }, animationTimeMs);
    },
    [setAnimationVisible, lottieRef],
  );

  const stopAnimation = useCallback(() => {
    setAnimationVisible(false);
  }, [setAnimationVisible]);

  const memoizedValues = useMemo(
    () => ({ animationVisible, startAnimation, stopAnimation }),
    [animationVisible, startAnimation, stopAnimation],
  );

  return (
    <AnimationContext.Provider value={memoizedValues}>
      {/** instead of just hiding the backdrop (but it always being rendered) -- conditionally render it instead */}
      {animationVisible && (
        <Backdrop sx={sxProps.backdrop} invisible open>
          <Lottie
            animationData={confetti}
            loop={false}
            lottieRef={lottieRef}
            onDOMLoaded={() => lottieRef.current?.setSpeed(DEFAULT_ANIMATION_SPEED)}
          />
        </Backdrop>
      )}
      {children}
    </AnimationContext.Provider>
  );
};
